import {
  AddCommentToBookingDocument,
  CalendarBookingsDocument,
  CalendarBookingsQueryVariables,
  ChangeBookingStatusDocument,
  EditBookingDateTimeDocument,
  GetBookingCancellationReasonsDocument,
  ViewBookingDocument,
} from '@/graphql/Bookings';
import {
  BookedItem,
  BookedService,
  BookedServiceAdminViewOutput,
  BookedServiceStatusHistoryOutput,
  ClosedPeriod,
  StoreBooking,
  UpdateBookingTimeInput,
  Admin,
  UpdateBookingStatusInput,
  JsmStatusHistory,
  UpdateSosRequestLocationInput,
  OrderVehicle,
} from 'graphql-types.gen';
import { MaybeReactive, useMutation, useQuery } from 'villus';
import { toNonNullable } from '@/utils/collections';
import { CalendarEvent } from 'vuetify';
import { computed, ref } from '@vue/composition-api';
import { format } from 'date-fns';
import { ar } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import { UpdateJsRequestLocationDocument } from '@/graphql/Jumpstart';
import { UpdateVsRequestLocationDocument } from '@/graphql/Vans';
import { ReservationeEnum } from '@/features/reservation';

export type CalendarBooking = ReturnType<typeof mapCalendarBooking>;
export type CalendarBookingOrder = ReturnType<typeof mapCalendarBookingOrder>;
export type BookingStatusHistoryType = ReturnType<typeof mapStatusHistory>;

export const storeType = ref<string>('STORE');
export function useCalendarBookings(variables: MaybeReactive<CalendarBookingsQueryVariables>) {
  const { execute, isFetching } = useQuery({ query: CalendarBookingsDocument, variables, cachePolicy: 'network-only' });

  async function getCalendarBookings() {
    const { data, error } = await execute();

    if (error) {
      throw new Error(error.message);
    }

    storeType.value = data?.response?.type as string;

    const bookings: CalendarEvent[] = data?.response?.bookings?.orders
      .map(order =>
        toNonNullable(order?.services as Partial<BookedItem>[])
          .map(booking => mapCalendarBookingOrder(booking, order as StoreBooking))
          ?.filter(service => service?.isService)
      )
      .flat() as CalendarEvent[];

    const blockedSlots: CalendarEvent[] = toNonNullable(data?.response?.closedPeriods).map(item =>
      mapBlockedTime(item, storeType.value)
    ) as CalendarEvent[];

    return [...bookings, ...blockedSlots];
  }

  return {
    getCalendarBookings,
    isFetchingBookings: isFetching,
    storeType,
  };
}

export function useEditBookingDateTime() {
  const { execute, isFetching } = useMutation(EditBookingDateTimeDocument);

  async function editBooking(input: UpdateBookingTimeInput) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return mapCalendarBooking(data.response as BookedService);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    editBooking,
    isEditing: isFetching,
  };
}

export function useChangeBookingStatus() {
  const { execute, isFetching } = useMutation(ChangeBookingStatusDocument);

  async function changeBookingStatus(input: UpdateBookingStatusInput) {
    try {
      const { data, error } = await execute({
        input,
      });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    changeBookingStatus,
    isChangingStatus: isFetching,
  };
}

export function useAddCommentToBooking() {
  const { execute, isFetching } = useMutation(AddCommentToBookingDocument);

  async function addComment(bookingUid: string, comment: string) {
    try {
      const { data, error } = await execute({
        input: { booking_uid: bookingUid, comment },
      });

      if (error) {
        throw new Error(error.message);
      }

      return toNonNullable(mapCalendarBooking(data.response as BookedService)?.comments);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    addComment,
    isAddingComment: isFetching,
  };
}

export function useViewBooking() {
  const { execute } = useMutation(ViewBookingDocument);

  async function viewBooking(uid: string) {
    try {
      const { data, error } = await execute({
        uid,
      });

      if (error) {
        throw new Error(error.message);
      }

      return toNonNullable(data.response.admin_views_for_booked_service as BookedServiceAdminViewOutput[]).map(mapView);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    viewBooking,
  };
}

export function useGetBookingCancellationReasons() {
  const {
    data,
    isFetching,
    execute: fetchReasons,
  } = useQuery({ query: GetBookingCancellationReasonsDocument, fetchOnMount: false });

  const reasons = computed(() => {
    if (!data.value) {
      return [];
    }
    return [...(data.value?.reasons as string[]), 'أخرى'] || [];
  });

  return {
    reasons,
    isFetchingReasons: isFetching,
    fetchReasons,
  };
}

type ApiOrderType = StoreBooking & {
  services?: BookedItem[];
};

export function mapCalendarBookingOrder(apiBooking: Partial<BookedItem>, apiOrder: ApiOrderType): CalendarEvent {
  // Map jumpstart status history if exists
  const jumpstartStatusHistory = apiOrder?.jump_start_info?.status_history?.length
    ? toNonNullable(apiOrder?.jump_start_info?.status_history)?.map(mapStatusHistory)
    : [];

  // Map van status history if exists
  const vanStatusHistory = apiOrder?.van_service_info?.status_history?.length
    ? toNonNullable(apiOrder?.van_service_info?.status_history)?.map(mapStatusHistory)
    : [];

  /**
   * A label representing the vehicle information from the `apiOrder`.
   * If the `vehicle` object is present, it maps the vehicle details using `mapBookingVehicle`.
   * If the `vehicle` object is absent, it defaults to the string 'لا يوجد' (Arabic for 'Not available').
   *
   * @type {string}
   */
  const vehicleLabel = apiOrder.vehicle ? mapBookingVehicle(apiOrder.vehicle) : 'لا يوجد';

  return {
    orderNumber: apiOrder.number,
    vehicleLabel,
    customer: apiOrder.customer,
    name: apiBooking.product_name,
    isJumpstart: apiOrder?.is_jump_start_order,
    isVan: apiOrder?.is_van_service_order,
    jumpstartInfo: {
      status: apiOrder?.jump_start_info?.status?.toLowerCase(),
      rider: mapRider(apiOrder?.jump_start_info?.rider as Admin),
      statusHistory: jumpstartStatusHistory,
    },
    vanInfo: {
      status: apiOrder?.van_service_info?.status?.toLowerCase(),
      rider: mapRider(apiOrder?.van_service_info?.rider as Admin),
      statusHistory: vanStatusHistory,
    },
    price: apiBooking?.price?.value,
    sku: apiBooking?.product_sku,
    isService: !!apiBooking?.booking,
    products: mapProducts(apiOrder),
    status: getBookingStatus(apiBooking, apiOrder),
    startDate: apiOrder?.created_at ? formatDateInTimeZone(apiOrder?.created_at) : '',
    ...mapCalendarBooking(apiBooking.booking as BookedService),
  };
}

/**
 * Maps the services from an API order to a list of products with their name and id.
 *
 * @param {ApiOrderType} apiOrder - The API order object containing the services.
 * @returns {Array<{ name: string; id: string | undefined }>} - An array of products with their name and id.
 */
function mapProducts(apiOrder: ApiOrderType) {
  const products = toNonNullable(apiOrder?.services as Partial<BookedItem>[])
    ?.filter(service => !service?.booking)
    .map(product => {
      return {
        name: product?.product_name,
        id: product?.uid,
      };
    });

  return products;
}

/**
 * Maps a rider object to an object with the rider's full name.
 *
 * @param {Admin} rider - The rider object.
 * @param {string} rider.firstname - The first name of the rider.
 * @param {string} rider.lastname - The last name of the rider.
 * @returns {Object|undefined} An object containing the rider's full name, or undefined if the rider is not provided.
 */
function mapRider(rider: Admin) {
  if (!rider) return;

  return {
    fullName: `${rider?.firstname} ${rider?.lastname}`,
  };
}

// Get booking status if order is jumpstart, van or not
function getBookingStatus(apiBooking: Partial<BookedItem>, apiOrder: StoreBooking) {
  if (apiOrder?.is_jump_start_order) {
    return apiOrder?.jump_start_info?.status?.toLowerCase();
  }
  if (apiOrder?.is_van_service_order) {
    return apiOrder?.van_service_info?.status?.toLowerCase();
  }

  return apiBooking?.booking?.status?.toLowerCase();
}

export function mapCalendarBooking(apiBookingService: Partial<BookedService>) {
  if (!apiBookingService) {
    return;
  }

  const startDateTime = new Date(`${apiBookingService.booked_day} ${apiBookingService.booking_start_time}`);
  const endDateTime = new Date(`${apiBookingService.booked_day} ${apiBookingService.booking_end_time}`);

  return {
    admin: apiBookingService.admin,
    bookingUid: apiBookingService.uid,
    uid: apiBookingService.bookable_service_uid,
    start: startDateTime,
    end: endDateTime,
    // For the Calendar API
    timed: true,
    category: apiBookingService.status?.toLowerCase(),
    storeSlotDuration: apiBookingService.store_duration_slots,
    cancellationReason: apiBookingService.cancellation_reason,
    cancellationComment: apiBookingService.cancellation_comment,
    comments: toNonNullable(apiBookingService.status_history_for_booked_service).map(mapComment),
  };
}

function mapBlockedTime(apiBlockedTime: ClosedPeriod, type: string) {
  const startDateTime = new Date(apiBlockedTime.from);
  const endDateTime = new Date(apiBlockedTime.to);

  return {
    uid: apiBlockedTime.uid,
    start: startDateTime,
    end: endDateTime,
    name: apiBlockedTime.reason,
    // For the Calendar API
    timed: true,
    category: apiBlockedTime?.is_buffer && type === 'VAN' ? 'buffered' : 'closed',
    status: apiBlockedTime?.is_buffer && type === 'VAN' ? 'buffered' : 'closed',
  };
}

export function mapComment(comment: BookedServiceStatusHistoryOutput) {
  return {
    username: comment.admin?.username,
    comment: comment.comment,
    createdAt: comment.created_at,
  };
}

export function mapView(view: BookedServiceAdminViewOutput) {
  return {
    username: view.admin?.username,
    role: view.admin?.role?.label,
    createdAt: view.created_at,
  };
}

/**
 * Formats and maps the status history from the API response to a more readable format.
 *
 * @param {JsmStatusHistory} statusApi - The status history object from the API response.
 * @returns {Object} The formatted status history object.
 * @returns {string} return.dateTime - The formatted date and time in Arabic.
 * @returns {string} return.status - The status from the API response.
 */
function mapStatusHistory(statusApi: JsmStatusHistory) {
  const fullName =
    statusApi?.admin?.firstname && statusApi?.admin?.lastname
      ? `${statusApi.admin.firstname} ${statusApi.admin.lastname}`
      : 'النظام';

  return {
    dateTime: statusApi?.created_at ? formatDateToArabic(String(statusApi?.created_at)) : '',
    status: statusApi?.status?.toLowerCase(),
    user: {
      fullName,
    },
  };
}

/**
 * Formats a given date string to the desired Arabic format.
 *
 * @param {string} dateString - The date string to be formatted.
 * @returns {string} The formatted date string in Arabic.
 */
function formatDateToArabic(date: string) {
  const dateTime = new Date(date);
  return format(dateTime, 'd MMMM yyyy, h:mm a', { locale: ar });
}

/**
 * Converts a UTC date string to the local time in the Cairo timezone (Africa/Cairo)
 * and formats it in Arabic.
 *
 * @param {string} date - The UTC date string to be converted and formatted. The date string
 * should be in the format 'yyyy-MM-dd HH:mm:ss'.
 * @returns {string} The formatted date string in the Cairo timezone, presented in Arabic.
 * The format is 'd MMMM yyyy, h:mm a', e.g., '19 أغسطس 2024, 3:25 م'.
 **/
function formatDateInTimeZone(date: string) {
  // Create a new Date object as if the string is in UTC
  const utcDate = new Date(date + 'Z'); // Appending 'Z' to ensure it's treated as UTC
  // Format the date in the Cairo timezone (Africa/Cairo) with Arabic localization
  const formattedDate = formatInTimeZone(utcDate, 'Africa/Cairo', 'd MMMM yyyy, h:mm a', { locale: ar });
  return formattedDate;
}

/**
 * useUpdateBookingLocation is a custom hook that provides functionality to update
 * the booking location based on the reservation type.
 */
export function useUpdateBookingLocation() {
  const isLoading = ref(false);

  const { execute: executeJsm } = useMutation(UpdateJsRequestLocationDocument);
  const { execute: executeVs } = useMutation(UpdateVsRequestLocationDocument);

  /**
   * Executes the mutation based on the reservation type.
   */
  async function mutate(input: UpdateSosRequestLocationInput, type: ReservationeEnum) {
    try {
      isLoading.value = true;

      const executeMutation = type === ReservationeEnum.Jumpstart ? executeJsm : executeVs;
      const { data, error } = await executeMutation({ input });

      if (error) {
        throw new Error(error?.message);
      }

      return data;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      isLoading.value = false;
    }
  }

  return { mutate, isLoading };
}

/**
 * Maps vehicle details into a formatted string containing the brand, model, and year.
 * If any of these properties are missing, they are excluded from the result.
 *
 * @param {OrderVehicle} vehicle - The vehicle object containing brand, model, and year properties.
 * @returns {string} A string formatted as "brand-model-year", with missing values excluded.
 */
function mapBookingVehicle(vehicle: OrderVehicle) {
  const brand = vehicle?.brand ? vehicle?.brand : '';
  const model = vehicle?.model ? vehicle?.model : '';
  const year = vehicle?.year ? vehicle?.year : '';

  // Filter out empty strings and join the values with '-'
  const values = [brand, model, year].filter(Boolean);
  return values.join('-');
}
