import { render, staticRenderFns } from "./ChooseProduct.vue?vue&type=template&id=54284aea&scoped=true&"
import script from "./ChooseProduct.vue?vue&type=script&lang=ts&"
export * from "./ChooseProduct.vue?vue&type=script&lang=ts&"
import style0 from "./ChooseProduct.vue?vue&type=style&index=0&id=54284aea&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54284aea",
  null
  
)

export default component.exports