import {
  AddServiceToStoreDocument,
  BlockTimeSlotDocument,
  BranchDocument,
  BranchesByServicesDocument,
  BranchesByServicesQueryVariables,
  BranchesDocument,
  BranchNameDocument,
  DeleteBlockTimeDocument,
  GetStoreBlockingReasonsDocument,
  UpdateBlockTimeDocument,
  UpdateBranchServiceSettingsDocument,
  UpdateBranchStatusDocument,
  UpdateOpeningTimesDocument,
  FindStoreDocument,
  FindStoreQueryVariables,
  ImportBookableServicesDocument,
} from '@/graphql/Branches';
import { computed, ComputedRef } from '@vue/composition-api';
import {
  BookableServiceInput,
  CloseStoreLocatorInput,
  WorkingTimesInput,
  UpdateBookableServiceInput,
  UpdateClosedPeriodInput,
  BookableServiceImportInput,
  StoreTypeEnum,
} from '../../graphql-types.gen';
import { MaybeReactive, useMutation, useQuery } from 'villus';
import { mapService } from './services';
import { useMutationWrapper } from '@/features/wrappers';

export function useBranches() {
  const { data, isFetching } = useQuery({ query: BranchesDocument });

  const branches = computed(() => data.value?.branches.nodes || []);

  return {
    branches,
    isFetchingBranches: isFetching,
  };
}

export function useBranch(id: number) {
  const { data, isFetching, execute } = useQuery({
    query: BranchDocument,
    variables: { id },
    cachePolicy: 'network-only',
  });

  const branch = computed(() => data.value?.branch);

  return {
    branch,
    isFetchingBranches: isFetching,
    refetchBranch: execute,
  };
}

export function useBranchName(id: number) {
  const { data } = useQuery({ query: BranchNameDocument, variables: { id } });

  const branchName = computed(() => data.value?.branch?.name);

  return {
    branchName,
  };
}

export function useBranchServiceSettings() {
  const { execute, isFetching } = useMutation(UpdateBranchServiceSettingsDocument);

  async function updateSettings(input: [UpdateBookableServiceInput]) {
    try {
      const { data, error } = await execute({ input });

      if (error) {
        throw new Error(error.message);
      }

      return data.response.branch;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateSettings,
    isUpdatingSettings: isFetching,
  };
}

export function useBranchOpeningTimes() {
  const { execute, isFetching } = useMutation(UpdateOpeningTimesDocument);

  async function updateOpeningTimes(storeLocatorId: number, input: WorkingTimesInput) {
    try {
      const { data, error } = await execute({ storeLocatorId, input });

      if (error) {
        throw new Error(error.message);
      }

      return data.response.branch;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateOpeningTimes,
    isUpdatingTimes: isFetching,
  };
}

export function useAddServiceToBranch() {
  const { execute, isFetching } = useMutation(AddServiceToStoreDocument);

  async function addService(storeLocatorId: number, bookableServices: [BookableServiceInput]) {
    try {
      const { data, error } = await execute({ storeLocatorId, bookableServices });

      if (error) {
        throw new Error(error.message);
      }

      return data.response.branch;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    addService,
    isAddingService: isFetching,
  };
}

export function useBranchStatus() {
  const { execute, isFetching } = useMutation(UpdateBranchStatusDocument);

  async function updateBranchStatus(storeLocatorId: number, status: boolean) {
    try {
      const { data, error } = await execute({ storeLocatorId, status });

      if (error) {
        throw new Error(error.message);
      }

      return data.response.branch;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    updateBranchStatus,
    isUpdatingStatus: isFetching,
  };
}

export function useBranchesByCity(cityId: number) {
  const { data, isFetching } = useQuery({
    query: BranchesDocument,
    variables: { cityId, withBranchData: true, storeType: { eq: StoreTypeEnum.Store } },
  });

  const branches = computed(() => data.value?.branches.nodes || []);

  return {
    branches,
    isFetchingBranches: isFetching,
  };
}

export function useBranchesByServices(variables: MaybeReactive<BranchesByServicesQueryVariables>) {
  const {
    data,
    execute,
    unwatchVariables,
    isFetching: isFetchingBranches,
  } = useQuery({
    query: BranchesByServicesDocument,
    variables,
    fetchOnMount: false,
  });
  unwatchVariables();

  // Get branches that have all these services
  const branches = computed(() =>
    data.value?.branches.nodes
      ?.filter(b => b?.services?.totalCount === (variables as ComputedRef).value.filter.sku.in.length)
      .map(branch => {
        return {
          ...branch,
          services: branch?.services?.nodes?.map(mapService),
        };
      })
  );

  return {
    branches,
    refetchBranches: execute,
    isFetchingBranches,
  };
}

export function useGetStoreBlockingReasons() {
  const { data, isFetching } = useQuery({ query: GetStoreBlockingReasonsDocument });

  const reasons = computed(() => {
    if (!data.value) {
      return [];
    }
    return [...(data.value?.reasons as string[]), 'أخرى'] || [];
  });

  return {
    reasons,
    isFetchingReasons: isFetching,
  };
}

export function useBlockTimeSlot() {
  return useMutationWrapper<CloseStoreLocatorInput>(BlockTimeSlotDocument, 'response');
}

export function useUpdateBlockedTimeSlot() {
  return useMutationWrapper<UpdateClosedPeriodInput>(UpdateBlockTimeDocument, 'response');
}

export function useDeleteBlockedTimeSlot() {
  const { execute, isFetching } = useMutation(DeleteBlockTimeDocument);

  async function deleteBlockedTimeSlot(closedPeriodUid: string) {
    try {
      const { data, error } = await execute({ closedPeriodUid });

      if (error) {
        throw new Error(error.message);
      }

      return data.response;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      throw err;
    }
  }

  return {
    deleteBlockedTimeSlot,
    isDeleting: isFetching,
  };
}

/*
 * Custom hook to fetch store data and provide reactive store ID and loading state.
 */
export function useFindStore(variables: MaybeReactive<FindStoreQueryVariables>) {
  const { data, isFetching } = useQuery({
    query: FindStoreDocument,
    variables,
    cachePolicy: 'network-only',
    fetchOnMount: false,
  });

  /*
   * Computed property representing the ID of the store.
   */
  const storeId = computed(() => {
    return data.value?.response?.store?.id;
  });

  return {
    storeId,
    isFetching,
  };
}

/**
 * Custom hook to handle importing bookable services.
 * */
export function useImportBookableService() {
  const { execute, isFetching } = useMutation(ImportBookableServicesDocument);

  /**
   * Performs the import operation for bookable services.
   *
   * @param {number} id - The ID of the store locator where the services will be imported.
   * @param {BookableServiceImportInput[]} input - An array of objects representing the bookable services to import.
   * @throws {Error} Throws an error if the import operation fails.
   */
  const mutate = async (id: number, input: BookableServiceImportInput[]) => {
    try {
      const { error } = await execute({ storeLocatorId: id, input });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    }
  };

  return {
    mutate,
    isFetching,
  };
}
