<template>
  <div class="RadioInput">
    <div class="RadioInput__Control">
      <div
        role="label"
        :id="id"
        class="w-full cursor-pointer font-bold"
        :class="{ 'cursor-not-allowed pointer-events-none': disabled }"
        @click="onChange"
      >
        <slot>
          {{ label }}
        </slot>
      </div>
      <button
        v-if="showCircle"
        class="w-5 h-5 rounded-full flex items-center justify-center flex-shrink-0"
        :class="{ 'cursor-not-allowed pointer-events-none': disabled, checked: checked }"
        type="button"
        :disabled="disabled"
        @click="onChange"
      >
        <div class="w-3 h-3 rounded-full" :class="{ 'bg-blue-100': checked }"></div>
      </button>
      <input
        :aria-labelledby="id"
        :checked="checked"
        :value="value"
        :name="name"
        type="radio"
        class="invisible"
        :disabled="disabled"
        @change="onChange"
      />
    </div>
    <slot name="after" />
  </div>
</template>

<script>
import { get as getPath } from 'lodash-es';

export default {
  name: 'RadioInput',
  model: {
    prop: 'modelValue',
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: undefined,
    },
    value: {
      type: null,
      default: '',
    },
    modelValue: {
      type: null,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
    showCircle: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked() {
      if (this.trackBy) {
        return getPath(this.value, this.trackBy) === getPath(this.modelValue, this.trackBy);
      }

      return this.value === this.modelValue;
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.value);
    },
  },
};
</script>

<style lang="postcss" scoped>
/* purgecss start ignore */
.RadioInput {
  &__Control {
    @apply flex;
    button {
      @apply ml-2 flex-shrink-0;
      order: -1;
      border: 1px solid #aeaeae;

      &.checked {
        border-color: #3699ff;
      }
    }
  }
}
/* purgecss end ignore */
</style>
