<template>
  <div class="relative border-t border-gray-200 pt-7">
    <p class="flex items-center gap-x-4 font-bold">
      تعيين موظف

      <button
        v-if="isEditButtonVisible"
        class="flex items-center gap-2 underline font-normal text-gray-500 text-sm"
        @click="toggle = true"
      >
        <svg width="12" height="12" role="presentation" class="fill-current">
          <use xlink:href="@/assets/svg/symbol/svg/sprite.symbol.svg#edit"></use>
        </svg>
        تعديل
      </button>
    </p>

    <p class="text-sm mt-3"><span class="font-bold">الموظف:</span> {{ riderName ? riderName : 'لا يوجد' }}</p>

    <ValidationObserver v-if="toggle" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <SelectInput
          v-model="employee"
          v-load="false"
          name="employee"
          :options="employeeOptions"
          label-path="label"
          value-path="value"
          rules="required"
        />

        <div class="flex gap-x-3 mt-4">
          <AppButton v-load="isLoading" type="submit" :disabled="invalid || isLoading" class="Button bg-blue-100">
            تعيين موظف
          </AppButton>
          <AppButton type="button" class="Button bg-red-100" @click="toggle = false"> الغاء </AppButton>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { load } from '@/directives/loading';
import { ValidationObserver } from 'vee-validate';
import SelectInput from '@/components/SelectInput.vue';
import AppButton from '@/components/AppButton.vue';
import { useUsers } from '@/features/users';
import { useAlerts } from '@/features/alerts';
import { useEventBus } from '@/features/events';
import { ReservationeEnum, useAssginReservationRider } from '@/features/reservation';

export default defineComponent({
  name: 'ServiceAppointingRider',
  components: { SelectInput, AppButton, ValidationObserver },
  props: {
    type: {
      type: String,
      default: '',
    },
    bookingUid: {
      type: String,
    },
    rider: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: '',
    },
  },
  directives: {
    load,
  },
  setup(props) {
    const toggle = ref(false);
    const employee = ref(null);
    const { emit: emitCustomEvent } = useEventBus();

    // Set default rider name
    const riderName = ref(props?.rider ? `${props?.rider?.firstname} ${props?.rider?.lastname}` : '');
    const roleId = props?.type === ReservationeEnum.Van ? '1006' : '1004';

    const variables = ref({
      pageSize: 300,
      filter: {
        role_id: {
          eq: roleId,
        },
      },
    });
    const { success, error } = useAlerts();
    const { users: riders } = useUsers(variables);
    const { mutate, isLoading } = useAssginReservationRider();

    const employeeOptions = computed(() => {
      return riders.value?.map(rider => {
        return {
          label: `${rider?.firstname} ${rider?.lastname}`,
          value: rider?.id,
        };
      });
    });

    // On submit action
    async function onSubmit() {
      try {
        const result = await mutate({ bookingUid: props?.bookingUid, riderId: employee?.value }, props?.type);

        const currentRider = result?.response?.rider;

        if (currentRider) {
          riderName.value = `${currentRider?.firstname} ${currentRider?.lastname}`;
        }

        toggle.value = false;
        emitCustomEvent('BOOKINGS_UPDATED');
        success('تم تعيين  موظف');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err);
      }
    }

    /**
     * Determines the visibility of the edit button based on the current status and toggle state.
     *
     * @returns {boolean} `true` if the edit button should be visible, `false` otherwise.
     */
    const isEditButtonVisible = computed(() => {
      const statuses = ['completed', 'canceled', 'closed'];

      // Check if the current status is not in the list of statuses where editing is not allowed.
      const isStatusValid = !statuses.includes(props?.status);

      // The edit button is visible if the toggle is off and the status is valid.
      return !toggle.value && isStatusValid;
    });

    return {
      toggle,
      employee,
      employeeOptions,
      onSubmit,
      riderName,
      isLoading,
      isEditButtonVisible,
    };
  },
});
</script>

<style lang="postcss" scoped>
.Button {
  min-width: auto;
  @apply text-white rounded-md text-sm;
}
</style>
