import { render, staticRenderFns } from "./ChooseCustomer.vue?vue&type=template&id=387c9696&scoped=true&"
import script from "./ChooseCustomer.vue?vue&type=script&lang=ts&"
export * from "./ChooseCustomer.vue?vue&type=script&lang=ts&"
import style0 from "./ChooseCustomer.vue?vue&type=style&index=0&id=387c9696&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "387c9696",
  null
  
)

export default component.exports