<template>
  <div>
    <transition name="fade">
      <div v-if="imagesUrl.length" class="flex flex-wrap gap-4 mb-6">
        <div v-for="(item, index) in imagesUrl" :key="index" class="relative upload-input-preview">
          <img :src="item" alt="image" width="335" height="200" />
          <button
            type="button"
            class="upload-input-delete rounded-full flex items-center justify-center bg-white"
            @click="onDeleteImage(index)"
          >
            <SvgIcon name="close" width="18" height="18" class="fill-current" />
          </button>
        </div>
      </div>
    </transition>

    <label for="upload-input" class="flex items-center gap-x-2 mt-6 cursor-pointer underline font-bold">
      <SvgIcon name="plus" width="12" height="12" class="fill-current" />
      تحميل صورة للبطارية (إختيارى)
      <input
        id="upload-input"
        ref="uploadInput"
        type="file"
        class="hidden"
        :multiple="multiple"
        accept="image/*"
        @change="onChangeAction"
      />
    </label>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  name: 'UploadInput',
  components: {
    SvgIcon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imagesUrl: [],
      files: [],
    };
  },
  watch: {
    files(value) {
      const currentValue = this.multiple ? value : value[0];
      this.$emit('input', currentValue);
      this.onPreviewImages();
    },
  },
  methods: {
    onChangeAction(e) {
      const currentFiles = e?.target?.files;
      if (!currentFiles?.length) return;

      // On filter image files
      const images = this.filterImageFiles(currentFiles);

      // Update the component's files state based on the multiple flag
      if (this.multiple) {
        this.files = [...images, ...this.files];
      } else if (images?.length && !this.multiple) {
        this.files = images;
      } else {
        this.files = [...this.files];
      }

      // Reset input file value
      this.$refs.uploadInput.value = '';
    },
    /**
     * Generates object URLs for each selected file and adds
     * them to the imagesUrl array for preview.
     *
     * @param {FileList} files - The list of selected files from the input file element.
     */
    onPreviewImages() {
      this.imagesUrl = [];
      for (const file of this.files) {
        const url = URL.createObjectURL(file);
        this.imagesUrl.push(url);
      }
    },
    /**
     * Deletes an image from the files array based on the specified index.
     *
     * @param {number} index - The index of the image to be deleted.
     *
     */
    onDeleteImage(index) {
      this.files?.splice(index, 1);
    },
    /**
     * Filters an array of files to include only image files.
     *
     * @param {FileList} files - The array of files to be filtered.
     * @returns {File[]} An array containing only image files.
     */
    filterImageFiles(files) {
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB

      const images = Array.from(files)?.filter(
        file => file?.type?.startsWith('image/') && file?.size <= maxSizeInBytes
      );

      return images;
    },
  },
};
</script>

<style lang="postcss" scoped>
.upload {
  &-input {
    &-preview {
      @apply w-full;
      width: 335px;
      height: 200px;
      max-width: 100%;

      > img {
        @apply w-full h-full object-fill;
      }
    }
    &-delete {
      @apply absolute;
      min-width: 40px;
      min-height: 40px;
      top: 10px;
      right: 10px;
    }
  }
}
</style>
